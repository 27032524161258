import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { SEO, Layout, PreviewCompatibleImage } from "../components";
import Content, { HTMLContent } from "../components/Content";
import { Container, Wrapper } from "../ui/LayoutComponents";
import { PostTitle, SubtitleBlue } from "../ui/Texts";
import { ImageContainer } from "../ui";
import { getImageWidth } from "../utils/functions";

export const TemoignagePostTemplate = ({
  title,
  subtitle,
  description,
  image,
  alt,
  slug,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;
  const imgWidth = getImageWidth(image.childImageSharp.fluid);

  return (
    <>
      <SEO
        article
        title={title}
        description={description}
        url={slug}
        image={image.childImageSharp.fluid.src}
      />
      <Container>
        <PostTitle>{title}</PostTitle>
        <SubtitleBlue>{subtitle}</SubtitleBlue>
        <Wrapper>
          <ImageContainer maxWidth={imgWidth} margin="auto" imgLeft imgRight marginB="2rem ">
            <PreviewCompatibleImage imageInfo={{ image, alt }} />
          </ImageContainer>
          <PostContent className="markdown" content={content} />
        </Wrapper>
      </Container>
    </>
  );
};

TemoignagePostTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string,
  slug: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
};

const TemoignagePost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <TemoignagePostTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        description={post.frontmatter.description}
        image={post.frontmatter.image}
        alt={post.frontmatter.alt}
        slug={post.fields.slug}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

TemoignagePost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default TemoignagePost;

export const pageQuery = graphql`
  query TemoignagePostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        title
        subtitle
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        alt
      }
    }
  }
`;
